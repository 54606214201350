import React, { Component } from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  Animated,

} from 'react-native';

import logo from './images/logo_baumaschinenheld.png';

class CustomBlinkingTxt extends Component {
  constructor(props) {
    super(props);
    this.state = { showText: true };
    // Change the state every second or the time given by User.
    setInterval(() => {
        this.setState(previousState => {
          return { showText: !previousState.showText };
        });
      },
      // Define blinking time in milliseconds
      800
    );
  }
  render() {
    let display = this.state.showText ? this.props.text : ' ';
    return (
      <Text style={styles.blink}>{display}</Text>
    );
  }
}
 
 
class ImageLoader extends Component{
	
	state = {
		
			opacity: new Animated.Value(0),
	}
	
	onLoad = () =>{
		
		Animated.timing(this.state.opacity,{
			
			toValue: 1,
			duration: 500,
			useNativeDriver: true,
			
		}).start();
		
	}
	
	render() {
		
		return(
			<Animated.Image
			
				onLoad={this.onLoad}
				{...this.props}
				style={[
					
					{
						opacity: this.state.opacity,
						transform:[
							{
								scale: this.state.opacity.interpolate({
									
									inputRange: [0,1],
									outputRange: [0.85,1],
									
								})
							}
						]
						
					},
					this.props.style, 
					
					]
					
				}
				
			/>
			
		)
		
	}
	
}


class App extends Component {
 
  render() {
       return (
      <View style={styles.container}>
        <ImageLoader source={logo} style={{width:360, height:254}}/>
        <Text style={styles.title}>Unser Angebot befindet sich im Aufbau.</Text>
         <CustomBlinkingTxt text="Bitte schauen Sie später wieder vorbei"/>
       </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    marginTop: 20,
    marginBottom:20,
    color:'rgb(171,45,30)',
    textTransform: 'uppercase',
  },
  blink:{
	  fontWeight: 'normal',
	  fontSize: 12,
	  color:'rgb(55,59,142)',
	  
  }
 });

let hotWrapper = () => () => App;
if (Platform.OS === 'web') {
  const { hot } = require('react-hot-loader');
  hotWrapper = hot;
}
export default hotWrapper(module)(App);
